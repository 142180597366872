@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

html,
body {
  background: $black;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: $white;
  width: 100%;
  @media only screen and (max-width: $md) {
    font-size: 1.1rem;
  }
  @media only screen and (max-width: $sm) {
    font-size: 1rem;
  }
}

#root .container {
  margin: 0;
  padding: 20px 20px 0 80px;
  max-width: 100%;
  overflow: hidden !important;
  @media only screen and (max-width: $md) {
    padding-top: 0;
  }
  @media only screen and (max-width: $sm) {
    padding: 0px 15px 0 15px;
  }
  @media only screen and (max-width: $xs) {
    padding: 0px 10px 0 10px;
  }
}

h1 {
  font-size: 5rem;
  @media only screen and (max-width: $xxl) {
    font-size: 4rem;
  }
  @media only screen and (max-width: $lg) {
    font-size: 3rem;
  }
  @media only screen and (max-width: $sm) {
    font-size: 2.5rem;
  }
  @media only screen and (max-width: $xs) {
    font-size: 2.2rem;
  }
  @media only screen and (max-width: $xxs) {
    font-size: 1.8rem;
  }
}

h2 {
  margin: 20px 0;
  font-size: 4rem;
  @media only screen and (max-width: $xxl) {
    font-size: 3.5rem;
  }
  @media only screen and (max-width: $lg) {
    font-size: 2.7rem;
  }
  @media only screen and (max-width: $sm) {
    font-size: 2.2rem;
  }
  @media only screen and (max-width: $xs) {
    font-size: 2rem;
  }
  @media only screen and (max-width: $xxs) {
    font-size: 1.6rem;
  }
}

h3 {
  margin: 10px 0;
  font-size: 2.5rem;
  @media only screen and (max-width: $lg) {
    font-size: 2.1rem;
  }
  @media only screen and (max-width: $sm) {
    font-size: 1.8rem;
  }
  @media only screen and (max-width: $xs) {
    font-size: 1.5rem;
  }
  @media only screen and (max-width: $xxs) {
    font-size: 1.4rem;
  }
}

h4 {
  font-size: 1.5rem;
  @media only screen and (max-width: $xs) {
    font-size: 1.3rem;
  }
  @media only screen and (max-width: $xxs) {
    font-size: 1.2rem;
  }
}

h5 {
  font-size: 1.3rem;
  @media only screen and (max-width: $md) {
    font-size: 1.2rem;
  }
  @media only screen and (max-width: $sm) {
    font-size: 1.1rem;
  }
  @media only screen and (max-width: $xs) {
    font-size: 1rem;
  }
}

a {
  color: $white;
  text-decoration: underline;
  &:hover {
    color: $light-gray;
  }
}

.swiper-container {
  max-width: 100% !important;
  margin-top: 20px !important;
  clip-path: inset(0 -100px -100px 0);
  overflow: visible !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.main-container {
  padding-top: 100px;
  padding-bottom: 20px;
}
