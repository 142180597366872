@import '../../assets/variables';

.rating-explanation {
  height: 100%;
  margin: 0 15px 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: $md) {
    margin: 0 10px 0 0;
  }
  h6 {
    margin: auto;
    margin-top: 10px;
    margin-left: 4px;
    @media only screen and (max-width: $xs) {
      font-size: 0.8rem;
    }
    @media only screen and (max-width: $xxs) {
      font-size: 0.78rem;
    }
  }
}

.explanation-rating {
  float: left;
}

.technical-skills {
  width: 100%;
  display: inline-flex;
  align-items: center;
  @media only screen and (max-width: $md) {
    display: inline-block;
  }
}

.my-custom-pagination-div {
  margin-top: 20px;
  width: 100%;
  text-align: center;

  .swiper-pagination-bullet {
    background: $gray !important;
    width: 25px !important;
    height: 25px !important;
    opacity: 1;
    margin: 0 15px;
  }
  .swiper-pagination-bullet-active {
    background: $white !important;
    width: 25px !important;
    height: 25px !important;
    opacity: 1;
    margin: 0 15px;
  }
}
