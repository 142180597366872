$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 450px;
$xxs: 380px;
$xxxs: 350px;

$black: black;
$white: white;
$light-gray: #d0d0d0;
$gray: gray;

$background-color: black;
$font-color: white;
$icon-color: white;
$anchor-color: white;
