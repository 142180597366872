@import '../../assets/variables';

.mobile-navbar {
  display: none;
  position: fixed;
  top: 0;
  transition: top 0.3s;
  z-index: 100;
  width: 100%;
  background: $black;
  padding: 10px 15px 0;
  box-shadow: 0px 20px 20px 15px rgba(0, 0, 0, 1);
  @media only screen and (max-width: $sm) {
    display: inline-block;
  }
}

.offcanvas {
  z-index: 9999;
  background: $black;
  border-right: 1px solid $light-gray;
}

.btn-dark {
  background: $black !important;
  border: none;
}

.offcanvas-header {
  justify-content: flex-end;
  .btn-close {
    margin-top: 5px !important;
    background: url('../../assets/images/close-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    z-index: 9999;
    width: 35px;
    right: 0;
  }
}

.offcanvas-image {
  width: 100%;
  padding: 0px 100px 0px;
  @media only screen and (max-width: $xs) {
    padding: 0px 90px 0px;
  }

  @media only screen and (max-width: $xxs) {
    padding: 0px 70px 0px;
  }

  @media only screen and (max-width: $xxxs) {
    padding: 0px 60px 0px;
  }
}

.sidebar-body {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  &__nav {
    padding: 10px;
    font-size: 1.5rem !important;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}
