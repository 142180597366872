@import '../../assets/variables';

.top-navbar-container {
  @media only screen and (max-width: $sm) {
    display: none;
  }
  background: $black;
  display: inline-flex;
  align-items: center;
  position: fixed;
  justify-content: space-around;
  width: 100%;
  padding: 10px 0 0;
  z-index: 5;
  box-shadow: 0px 20px 20px 15px rgba(0, 0, 0, 1);
  top: 0;
  transition: top 0.3s;
  &__nav {
    padding: 10px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}
