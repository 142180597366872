@import '../../assets/variables';

.sidebar {
  bottom: 0;
  font-size: 0.5rem;
  position: fixed;
  left: 0;
  width: 60px;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.icon {
  color: white;
  cursor: pointer;
  padding: 10px 13px;
  margin: 15px 10%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  text-decoration: none;
}

.iconContainer {
  width: 100%;
  height: 100%;

  div {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: $sm) {
  .sidebar {
    display: none;
  }
}
